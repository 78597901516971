<template>
  <login-component />
</template>

<script>
import store from '../store'
const LoginComponent = () => import('@/components/Login')

export default {
  name: 'LoginView',
  components: {
    LoginComponent
  },
  beforeRouteEnter (to, from, next) {
    store.commit('Layout/SET_LAYOUT', 'simple')
    next()
  },
  beforeRouteLeave (to, from, next) {
    store.commit('Layout/SET_LAYOUT', 'complex')
    next()
  }
}
</script>
